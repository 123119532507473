import React, { useState } from 'react';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import { useIsMobile } from 'hooks/use-size-class';
import Button from 'components/control/button';
import TextInput from '@zoocasa/node-kit/components/controls/text-input';
import styles from './style.module.scss';
import {
  usePreferencesContext,
  useThemeContext,
  useUserContext,
} from 'contexts';
import { SEARCH_OPTION_BUY } from 'themes/themeConfig';
import { buildClassName } from 'utils/build-class-name';
import type { SearchOptions } from 'themes/themeConfig';

/**
 * This component is a fake advanced search component that is used to display a search input field in the header.
 * Was created to mimic the advanced search component in the header, but not trigger the actual search.
 * On click on the search will close and open actual advanced search component.
 * Ticket - https://github.com/zoocasa/zoocasa-next/pull/2741
 */


const FakeAdvancedSearch = ({ isInPanel = false, isHomeAppraisal = false }) => {
  const { siteLocation } = useUserContext();
  const { lastSearchLocation } = usePreferencesContext();
  const { theme } = useThemeContext();
  const [isLocationDropdownActive, setIsLocationDropdownActive] = useState(isInPanel);
  const isMobile = useIsMobile();
  const [locationQuery, setLocationQuery] = useState(lastSearchLocation ? lastSearchLocation.description: '');

  const getPlaceholder = (type: SearchOptions) => {
    const searchOption = theme.searchOptions.find(option => option.type === type);
    return searchOption?.placeholder(siteLocation, isMobile);
  };
  const searchPlaceholder = getPlaceholder(SEARCH_OPTION_BUY);

  return (
    <div className={buildClassName(styles.component, styles['exp-theme'], isHomeAppraisal && styles['home-appraisal'], styles['fake-search'])}>
      <div className={buildClassName(styles['search-wrapper'], styles['exp-wrapper'])} ref={useOutsideClickHandler(() => setIsLocationDropdownActive(false))}>
        <TextInput
          name="search"
          placeholder={searchPlaceholder}
          value={locationQuery}
          onValueChange={setLocationQuery}
          autoComplete='off'
          autoFocus={isInPanel}
          data-isopen={isLocationDropdownActive.toString()}
        />
        <div className={styles['search-button-container']}>
          <Button label="Search" theme="primary" />
        </div>
      </div>
    </div>
  );
};

export default FakeAdvancedSearch;