import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { buildClassName } from 'utils/build-class-name';
import MenuButton from 'components/header/exp/menu-button';
import ThemedIcon from 'components/themed-icon';
import styles from './style.module.scss';
import { headerIds, testIds } from 'constants/test-constants';
import { useThemeContext } from 'contexts';
import DropdownArrowIcon from 'components/icon/dropdown-arrow-icon';

const CommercialHeader = () => {
  const { theme } = useThemeContext();
  const [menuDropdownIsActive, setMenuDropdownIsActive] = useState(false);
  const [aboutUsDropdownIsActive, setAboutUsDropdownIsActive] = useState(false);
  const [Dropdown, setDropdown] = useState<any>();
  const router = useRouter();
  const closeDropdownTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (closeDropdownTimeoutRef.current) {
        clearTimeout(closeDropdownTimeoutRef.current);
      }
    };
  }, []);

  const aboutUsLinks = [
    { label: 'Calendar', link: 'https://www.expcommercial.com/calendar' },
    { label: 'Media', link: 'https://life.exprealty.com' },
  ];

  const toggleMenuDropdown = () => {
    if (!Dropdown) {
      setDropdown(dynamic(import('components/dropdown')));
    }
    setMenuDropdownIsActive(prev => !prev);
  };

  const openAboutUsDropdown = () => {
    if (!Dropdown) {
      setDropdown(dynamic(import('components/dropdown')));
    }
    if (closeDropdownTimeoutRef.current) {
      clearTimeout(closeDropdownTimeoutRef.current);
      closeDropdownTimeoutRef.current = null;
    }
    setAboutUsDropdownIsActive(prev => !prev);
  };

  const closeAboutUsDropdown = () => {
    closeDropdownTimeoutRef.current = window.setTimeout(() => {
      setAboutUsDropdownIsActive(false);
      closeDropdownTimeoutRef.current = null;
    }, 200);
  };

  const isActive = (link: string) => {
    if (!router.pathname) return false;
    const currentPath = router.pathname.split('/')[1].toLowerCase();
    const pathParts = link.split('/');
    const path = pathParts[pathParts.length - 1].toLowerCase();
    return currentPath === path;
  };

  return (
    <div className={styles.component} data-testid={headerIds.header}>
      <div className={buildClassName(styles['layout-container'])}>
        <div className={buildClassName(styles['logo-wrapper'])}>
          <a href="https://www.expcommercial.com" className={styles.logo} rel="noreferrer"><ThemedIcon /></a>
        </div>
        <div className={styles.navigation}>
          {theme.menuLinks.map((link, index) => {
            if (link.label === 'About Us') {
              return (
                <div
                  key='about-us-menu-button'
                  className={styles['about-menu']}
                  onMouseEnter={openAboutUsDropdown}
                  onMouseLeave={() => closeAboutUsDropdown()}>
                  <span className={styles['menu-item']}>
                    <a key={index} href={link.link}>{link.label}</a>
                    <DropdownArrowIcon className={styles['dropdown-arrow-icon']} />
                  </span>
                  {aboutUsDropdownIsActive && Dropdown && (
                    <div className={styles['about-us-dropdown']}
                      onMouseEnter={() => setAboutUsDropdownIsActive(true)}
                      onMouseLeave={() => closeAboutUsDropdown()}>
                      <Dropdown items={aboutUsLinks} />
                    </div>
                  )}
                </div>
              );
            } else {
              return <a key={index} href={link.link} className={buildClassName(styles['menu-item'], isActive(link.link) && styles.active)}>{link.label}</a>;
            }
          })}
        </div>
        <MenuButton className={styles.menu} testId={testIds.menuButton} onClick={toggleMenuDropdown} closeMenu={() => setMenuDropdownIsActive(false)}>
          <>
            <div className={styles['bar-container']} id="menu">
              <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
              <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
              <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
            </div>
            {menuDropdownIsActive && Dropdown && <Dropdown items={theme.menuLinks} />}
          </>
        </MenuButton>
        <a href='https://www.expcommercial.com/advisor-center' className={styles['advisory-button']}>
          Advisor Sign-In
        </a>
      </div>
    </div>
  );
};

export default CommercialHeader;
