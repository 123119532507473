import React, { useEffect, useState } from 'react';
import { MapType, createSearchPredictionModel } from 'data/search-predictions';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import { useIsMobile } from 'hooks/use-size-class';
import useLocationSearch, { searchProvider } from 'hooks/use-location-search';
import Button from 'components/control/button';
import FilterIcon from 'components/icon/filter-icon';
import TextInput from '@zoocasa/node-kit/components/controls/text-input';
import { useRouter } from 'next/router';
import styles from './style.module.scss';
import {
  useFeaturesContext,
  useModalContext,
  usePreferencesContext,
  User,
  useThemeContext,
  useUserContext,
} from 'contexts';
import pushToRoute from 'utils/push-to-route';
import { searchFilterIds, testIds } from 'constants/test-constants';
import SearchOptionButtonGroup from './search-option-button-group';
import { searchOptions } from 'utils/select-options';
import { SEARCH_OPTION_AGENTS, SEARCH_OPTION_BUY, SEARCH_OPTION_RENT, SEARCH_OPTION_SOLD, SEARCH_OPTION_HOME_APPRAISAL } from 'themes/themeConfig';
import { AVAILABLE_STATUS, NOT_AVAILABLE_SOLD_STATUS } from 'contexts/preferences/listing-params/types';
import { isCanadianProvinceCode, ProvinceOrStateCode } from 'utils/province_or_state';
import { buildClassName } from 'utils/build-class-name';
import useMapLocationChanger from 'hooks/use-map-location-changer';
import { ThemeNames } from 'types/themes';
import dynamic from 'next/dynamic';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_APPRAISAL_HOME_SEARCH_BAR, GTM_CLICK_APPRAISAL_HOME_SEARCH_RESULT, GTM_CLICK_HOME_PAGE_SEARCH_BAR,
  GTM_CLICK_HOME_PAGE_SEARCH_BUTTON, GTM_CLICK_APPRAISAL_GOOGLE_ADDRESS, GTM_CLICK_APPRAISAL_LISTING_ADDRESS } from 'constants/events';
import { getListingById } from 'data/listing';
import { findPlaceByPlaceId } from 'utils/google-maps/geoLocator';
import HomeAppraisalStorage from 'utils/appraisal-storage';

import type ListingParams from 'contexts/preferences/listing-params';
import type SearchPrediction from 'data/search-predictions';
import type { SearchOptions } from 'themes/themeConfig';
import type { SearchAgentPrediction, SearchSuggestions } from 'components/suggested-location-dropdown';
import { AGENT_SEARCH_PATH } from 'utils/agent-endpoint';

interface Props {
  showsSearchOptions?: boolean;
  isFilterButtonHidden?: boolean;
  isInPanel?: boolean;
  hideSearchPanel?: () => void;
}

interface HandleLocationSearchProps {
  searchPrediction: SearchPrediction;
  listingParams: ListingParams;
  user: User | null;
  map?: MapType;
  addToRecentSearches: (searchPrediction: SearchPrediction) => void;
  setActivePrediction?: (searchPrediction: SearchPrediction) => void;
  themeName: ThemeNames;
}

export const handleSearchResult = ({ searchPrediction, listingParams, user, map, addToRecentSearches, setActivePrediction, themeName } : HandleLocationSearchProps) => {
  const prediction: SearchPrediction = !searchPrediction.transitionToPath ? createSearchPredictionModel(searchPrediction) : searchPrediction;
  addToRecentSearches(prediction);
  setActivePrediction?.(prediction);
  prediction.transitionToPath(listingParams, themeName, map, user);
};

const AdvancedSearch = ({ showsSearchOptions = false, isFilterButtonHidden = false, isInPanel = false, hideSearchPanel = () => {} }: Props) => {
  const { features, setIsSearchPanelOpen } = useFeaturesContext();
  const { openModal } = useModalContext();
  const router = useRouter();
  const isHomeAppraisal = router.pathname && router.pathname.startsWith('/home-appraisal');
  const { user, siteLocation } = useUserContext();
  const { listingParams, addToRecentSearches, lastSearchLocation } = usePreferencesContext();
  const { theme, themeName } = useThemeContext();
  const [activePrediction, setActivePrediction] = useState<SearchPrediction | SearchSuggestions>();
  const [isLocationDropdownActive, setIsLocationDropdownActive] = useState(isInPanel);
  const [onSearchPage, setOnSearchPage] = useState(false);
  const isMobile = useIsMobile();
  const [locationQuery, setLocationQuery] = useState(lastSearchLocation ? lastSearchLocation.description: '');
  // When user clicks on search inputs and it transforms to full screen search it looses the search options state
  const [currentSearchOptions, setCurrentSearchOptions] = useState<SearchOptions>(isHomeAppraisal ? SEARCH_OPTION_HOME_APPRAISAL : SEARCH_OPTION_BUY);
  const [SuggestedLocationDropdown, setSuggestedLocationDropdown] = useState<any>();
  const { isLoadingSearchPredictions, searchPredictions, searchAgentPredictions, isTyping } = useLocationSearch(
    locationQuery,
    features.useNewSearch,
    features.useUsListings,
    features.useGoogleSearch ? searchProvider.GOOGLE : searchProvider.APPLE,
    currentSearchOptions
  );
  const isExpTheme = themeName !== ThemeNames.ZOOCASA;
  const disablePopularSearches = currentSearchOptions.match(new RegExp(`${SEARCH_OPTION_AGENTS}|${SEARCH_OPTION_HOME_APPRAISAL}`, 'g'));

  const getPlaceholder = (type: SearchOptions) => {
    const searchOption = theme.searchOptions.find(option => option.type === type);
    return searchOption?.placeholder(siteLocation, isMobile);
  };

  const map: MapType = useMapLocationChanger();

  const searchPlaceholder = getPlaceholder(currentSearchOptions);
  const handleSearchOptionChange = (value: SearchOptions) => {
    setCurrentSearchOptions(value);
    if (value === SEARCH_OPTION_BUY) {
      listingParams.setRental(false);
      listingParams.setStatus(AVAILABLE_STATUS);
    } else if (value === SEARCH_OPTION_RENT) {
      listingParams.setRental(true);
      listingParams.setStatus(AVAILABLE_STATUS);
    } else if (value === SEARCH_OPTION_SOLD) {
      listingParams.setRental(false);
      listingParams.setStatus(NOT_AVAILABLE_SOLD_STATUS);
    }
  };

  const onSearchResultClick = async (searchPrediction: SearchPrediction | SearchAgentPrediction) => {
    trackEvent(GTM_CLICK_HOME_PAGE_SEARCH_BUTTON);
    if (isHomeAppraisal) {
      trackEvent(GTM_CLICK_APPRAISAL_HOME_SEARCH_RESULT);
      const eventName = (searchPrediction.group === 'locations') ? GTM_CLICK_APPRAISAL_GOOGLE_ADDRESS : GTM_CLICK_APPRAISAL_LISTING_ADDRESS;
      trackEvent(eventName);
    }

    if (currentSearchOptions === SEARCH_OPTION_AGENTS) { // go to eXp agent search page
      const locationArray = (searchPrediction as SearchAgentPrediction).label.split(',');
      const trimmedLocationArray = locationArray.map(part => part.trim()); // '['Old Toronto', 'Toronto', 'ON']
      const city = trimmedLocationArray[trimmedLocationArray.length - 2];
      const provinceOrState = trimmedLocationArray[trimmedLocationArray.length - 1];
      const country = isCanadianProvinceCode(provinceOrState.toLowerCase() as ProvinceOrStateCode) ? 'CA' : 'US';
      router.push(`${AGENT_SEARCH_PATH}/?location=${city}%2C+${provinceOrState}&country=${country}`);
    } else if (currentSearchOptions === SEARCH_OPTION_HOME_APPRAISAL) {
      const isListingPrediction = searchPrediction.group == 'listings';
      if (isListingPrediction) {
        const listingId = (searchPrediction as SearchPrediction).id?.split('-')?.[1];
        const listing = await getListingById(listingId);
        HomeAppraisalStorage.setProperty(listing);
      } else { // Means we got location prediction from Google
        const data = await findPlaceByPlaceId((searchPrediction as SearchPrediction).id);
        if (data) HomeAppraisalStorage.setProperty(data);
      }
      router.push('/home-appraisal/property-details');
    } else {
      handleSearchResult({
        searchPrediction: searchPrediction as SearchPrediction,
        listingParams,
        user,
        map: onSearchPage ? map : undefined,
        addToRecentSearches,
        setActivePrediction,
        themeName: themeName as ThemeNames,
      });
    }
    hideSearchPanel();
  };

  const openFullScreenSearchOrDropdown = () => {
    if (isMobile) {
      setIsSearchPanelOpen(true);
    } else {
      setIsLocationDropdownActive(true);
    }
  };

  const handleSearchButtonClick = () => {
    if (activePrediction && isLocationDropdownActive && locationQuery.length >= 3) {
      const isSearchSuggestion = (activePrediction as Record<string, unknown>)['group'] === undefined;
      if (isSearchSuggestion) {
        pushToRoute(activePrediction as SearchSuggestions, listingParams, router, themeName as ThemeNames, map);
      } else {
        onSearchResultClick(activePrediction as SearchPrediction);
      }
      hideSearchPanel();
    }
    else if (lastSearchLocation && locationQuery === lastSearchLocation.description) {
      onSearchResultClick(lastSearchLocation as SearchPrediction);
    }
  };

  useEffect(() => {
    // On mobile, the location search is hidden and the user only sees the advanced search. This logic is for if the user is on
    // the map page and they search for a location that has an area page, they should stay on the map instead of being redirected
    // to the area page.
    if (window.location.pathname.startsWith('/search')) {
      setOnSearchPage(true);
    } else {
      setOnSearchPage(false);
    }
    if (showsSearchOptions) {
      if (listingParams.filter.rental) {
        setCurrentSearchOptions(SEARCH_OPTION_RENT);
      } else if (listingParams.filter.status === NOT_AVAILABLE_SOLD_STATUS && !listingParams.filter.rental) {
        setCurrentSearchOptions(SEARCH_OPTION_SOLD);
      } else if (listingParams.filter.status === AVAILABLE_STATUS && !listingParams.filter.rental) {
        setCurrentSearchOptions(SEARCH_OPTION_BUY);
      }
    }

    if (window.location.pathname.startsWith('/home-appraisal')) {
      setCurrentSearchOptions(SEARCH_OPTION_HOME_APPRAISAL);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLocationDropdownActive && !SuggestedLocationDropdown) {
      setSuggestedLocationDropdown(dynamic(import('components/suggested-location-dropdown')));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocationDropdownActive]);

  return (
    <div className={buildClassName(styles.component, isExpTheme && styles['exp-theme'], isHomeAppraisal && styles['home-appraisal'])} data-testid={testIds.advancedSearch}>
      {showsSearchOptions && theme.searchOptions.length>1 &&
        <div className={styles['search-options']}>
          <SearchOptionButtonGroup
            isExpTheme={isExpTheme}
            options={searchOptions(theme.searchOptions)}
            onValueChange={handleSearchOptionChange}
            value={currentSearchOptions}
          />
        </div>
      }
      <div className={buildClassName(styles['search-wrapper'], isExpTheme && styles['exp-wrapper'])} ref={useOutsideClickHandler(() => setIsLocationDropdownActive(false))}>
        <TextInput
          name="search"
          placeholder={searchPlaceholder}
          value={locationQuery}
          onClick={() => {
            trackEvent(GTM_CLICK_HOME_PAGE_SEARCH_BAR);
            isHomeAppraisal && trackEvent(GTM_CLICK_APPRAISAL_HOME_SEARCH_BAR);
            openFullScreenSearchOrDropdown();
          }}
          onValueChange={setLocationQuery}
          autoComplete='off'
          autoFocus={isInPanel}
          data-isopen={isLocationDropdownActive.toString()}
          data-testid={searchFilterIds.searchbar}
        />
        {SuggestedLocationDropdown &&
          <SuggestedLocationDropdown
            isLoading={isLoadingSearchPredictions}
            searchPredictions={searchPredictions}
            setActivePrediction={setActivePrediction}
            onClick={onSearchResultClick}
            isActive={isLocationDropdownActive}
            className={styles['suggested-location-dropdown']}
            isAdvancedSearch={true}
            locationQuery={locationQuery}
            isTyping={isTyping}
            disablePopularSearches={disablePopularSearches}
            searchAgentPredictions={searchAgentPredictions}
          />
        }
        <div className={styles['search-button-container']}>
          {!isFilterButtonHidden && currentSearchOptions !== SEARCH_OPTION_HOME_APPRAISAL &&
            <Button label="Filter" Icon={FilterIcon} theme="secondary" onClick={() => openModal('saved-search-filters', { setAreaFilters: listingParams.setFilter })} testId={searchFilterIds.searchbarFilterButton} />
          }
          <Button label="Search" theme="primary" onClick={handleSearchButtonClick} testId={searchFilterIds.searchbarButton} />
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearch;